import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import urlJoin from 'url-join';
import { urls } from 'app-constants';
import SelectField from './SelectField';

const LiveSearchSelectField = ({
  objectType = 'event',
  minQueryLength = 1,
  components,
  name,
  ...props
}) => {
  const { watch } = useFormContext();
  const val = watch(name) || '';

  const [storedChoices, setStoredChoices] = useState([]);
  const [currentChoice, setCurrentChoice] = useState([]);
  const [labelCache, setLabelCache] = useState({});
  const updateLabelCache = vals => setLabelCache(oldState => ({ ...oldState, ...vals }));
  useEffect(() => {
    if (val) {
      if (labelCache[val]) {
        setCurrentChoice([[val, labelCache[val]]]);
        if (!storedChoices.map(([val]) => val).includes(val)) {
          setStoredChoices(oldState => [...oldState, [val, labelCache[val]]]);
        }
      } else {
        loadOptions(null, val).then(([{ label }]) => {
          setCurrentChoice([[val, label]]);
        });
      }
    } else {
      setCurrentChoice([]);
    }
  }, [val]);

  const loadOptions = (query, id) => {
    if (!id && query.length < minQueryLength) return Promise.resolve([]);

    const url = urlJoin(
      urls[`${objectType}LivesearchBase`],
      '/',
      id ? `?id=${id}` : `?q=${query}`,
    );
    return fetch(url)
      .then(response => {
        if (!response.ok) throw new Error(response.statusText);
        return response;
      })
      .then(response => response.json())
      .then(data => {
        const currentChoiceVal = currentChoice[0] && currentChoice[0][0];
        let choices = data.map(({ id, name }) => [id, name]);
        if (!id && !choices.map(([val]) => val).includes(currentChoiceVal)) {
          choices = [...choices, ...currentChoice];
        }
        const selectOptions = choices.map(([value, label]) => ({ value, label }));
        setStoredChoices(choices);
        updateLabelCache(choices.reduce((result, [value, label]) => ({ ...result, [value]: label }), {}));
        return selectOptions;
      })
      .catch(err => {
        console.error(err);
        return [];
      });
  };

  const [queryValue, setQueryValue] = useState('');

  // TODO fix
  // useEffect(() => {
  //   if (queryValue.length < minQueryLength) {
  //     setStoredChoices(currentChoice.length ? currentChoice : []);
  //   }
  // }, [queryValue, currentChoice.length]);

  const getOptionLabel = ({ label }) => {
    if (!queryValue) return label;
    const idx = label.toLowerCase().indexOf(queryValue.toLowerCase());
    const idxEnd = idx + queryValue.length;
    const html = `${label.slice(0, idx)}<strong class="color-primary">${label.slice(idx, idxEnd)}</strong>${label.slice(idxEnd)}`;
    return <span dangerouslySetInnerHTML={{ __html: html }} />;
  };

  const onInputChange = (value, actionMeta) => {
    if (actionMeta.action === 'input-change') {
      setQueryValue(value);
    } else {
      setQueryValue('');
    }
  };

  const emptyMessage = 'Begin typing to search…';
  const loadingMessage = ({ inputValue }) => inputValue.length < minQueryLength ? emptyMessage : 'Loading…';
  const noOptionsMessage = ({ inputValue }) => inputValue.length < minQueryLength ? emptyMessage : 'No matches found.';

  return (
    <SelectField
      async
      choices={storedChoices}
      components={components}
      asyncLoadOptions={q => loadOptions(q)}
      asyncProps={{
        onInputChange,
        getOptionLabel,
        loadingMessage,
        noOptionsMessage,
      }}
      name={name}
      {...props}
    />
  );
};

LiveSearchSelectField.propTypes = {
  objectType: PropTypes.oneOf(['event', 'poi']),
  components: PropTypes.object,
  minQueryLength: PropTypes.number,
  name: PropTypes.string,
};

export default LiveSearchSelectField;
