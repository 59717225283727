import React from 'react';
import PropTypes from 'prop-types';
import PillButton from 'components/PillButton';
import Cta from './Cta';

const RequestForm = ({
  isAuthenticated,
  isSubmitting,
  onSubmit,
  onCancel,
}) => {
  if (!isAuthenticated) {
    return (
      <Cta
        isAuthenticated={false}
        isNewVenue
        isCancellable
        onCancel={onCancel}
      />
    )
  }

  return (
    <div>
      <p>[WIP - new venue submission form]</p>
      <div>
        <PillButton
          text="Cancel"
          onClick={onCancel}
        />
      </div>
    </div>
  );
};

RequestForm.propTypes = {
  isAuthenticated: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
};

export default RequestForm;
