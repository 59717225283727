import { useState, useEffect, useRef } from 'react';

const BS_BREAKPOINTS = {
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1400,
  hd: 1600,
};

const BS_MEDIA_QUERIES = Object.entries(BS_BREAKPOINTS).reduce((result, [name, width]) => {
  result[`up-${name}`] = `(min-width: ${width}px)`;
  result[`down-${name}`] = `(max-width: ${(width - 0.02).toFixed(2)}px)`;
  return result;
}, {});

export default function useBootstrapBreakpoint (name) {
  const mediaQueryString = BS_MEDIA_QUERIES[name];
  const mqlRef = useRef(window.matchMedia(mediaQueryString));
  const [matches, setMatches] = useState(mqlRef.current.matches);

  useEffect(() => {
    try {
      const cb = mql => setMatches(mql.matches);
      mqlRef.current.addEventListener('change', cb);
      return () => mqlRef.current.removeEventListener('change', cb);
    } catch (e) {
      // noop
    }
  }, []);

  return matches;
}
