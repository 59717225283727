import React from 'react';
import PropTypes from 'prop-types';
import { useUserContext } from 'context';
import PillButton from 'components/PillButton';

const Cta = ({
  poiData,
  isClaimed = false,
  isAuthenticated = false,
  isNewVenue = false,
  isSubmitting = false,
  isCancellable = false,
  onSubmit,
  onCancel,
}) => {
  const { setAuthRequested } = useUserContext();

  if (!poiData && !isNewVenue) return null;

  const handleAuthClick = () => setAuthRequested({ register: true });
  const handleClaimClick = () => onSubmit(poiData.id);

  const cancelButton = isCancellable && (
    <PillButton
      className="lg"
      text="Cancel"
      onClick={onCancel}
    />
  );

  if (isClaimed) {
    return (
      <div>
        <strong>Oops!</strong> This venue has already been claimed. If you believe this is an error, please contact <a href="mailto:support@zeitcaster.com">support@zeitcaster.com</a>.
      </div>
    );
  }

  if (isAuthenticated) {
    return (
      <>
        <div className="mb-4">
          If you are an authorized representative of <strong>{poiData.name}</strong>, click below to submit your claim. After a brief verification process, we’ll send you an email with instructions to activate your subscription.
        </div>
        <div className="d-flex justify-content-center" style={{ gap: 20 }}>
          {cancelButton}
          <PillButton
            className="primary lg"
            text="Claim This Venue"
            isDisabled={isSubmitting}
            onClick={handleClaimClick}
          />
        </div>
      </>
    );
  }

  const msgText = isNewVenue
    ? <span>Please log in or register to submit your venue’s details for inclusion on Zeitcaster.</span>
    : <span>If you are an authorized representative of <strong>{poiData.name}</strong>, please log in or register to submit your claim.</span>;

  return (
    <>
      <div className="mb-4">
        {msgText}
      </div>
      <div className="d-flex justify-content-center" style={{ gap: 20 }}>
        {cancelButton}
        <PillButton className="primary lg" text="Log In / Register" onClick={handleAuthClick} />
      </div>
    </>
  );
};

Cta.propTypes = {
  poiData: PropTypes.object,
  isClaimed: PropTypes.bool,
  isAuthenticated: PropTypes.bool,
  isNewVenue: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  isCancellable: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
};

export default Cta;
