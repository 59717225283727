import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useUserContext } from 'context';
import { useToggle } from 'hooks';
import SearchForm from './SearchForm';
import RequestForm from './RequestForm';

const SUCCESS_TYPE_CLAIM = 'claim';
const SUCCESS_TYPE_SUBMIT_VENUE = 'submit';

const ClaimVenueForm = ({ headerLabel = 'Claim Your Venue' }) => {
  const { id: userId } = useUserContext();
  const [poiData, setPoiData] = useState(null);

  const [showRequestForm, toggleRequestForm] = useToggle(false);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [complete, setComplete] = useState(null);
  const handleSubmitClaim = id => {
    setIsSubmitting(true);
    setTimeout(() => {
      setComplete(SUCCESS_TYPE_CLAIM);
      setIsSubmitting(false);
    }, 1000);
  };

  // TODO update
  const msgText = {
    [SUCCESS_TYPE_CLAIM]: `We have received your request to claim <strong>${poiData?.name}</strong>. You will be notified by email when our team completes the verification process.`,
    [SUCCESS_TYPE_SUBMIT_VENUE]: `We have received your request. You will be notified by email when our team completes the verification process.`,
  }[complete];

  const successMsg = msgText && (
    <div>
      <h5 className="fw-bold">Thank You!</h5>
      <div dangerouslySetInnerHTML={{ __html: msgText }} />
    </div>
  );

  const form = showRequestForm ? (
    <RequestForm
      isAuthenticated={!!userId}
      isSubmitting={isSubmitting}
      onSubmit={() => console.log('submit new venue')}
      onCancel={toggleRequestForm}
    />
  ) : (
    <SearchForm
      poiData={poiData}
      isAuthenticated={!!userId}
      isSubmitting={isSubmitting}
      onSubmitClaim={handleSubmitClaim}
      onRequestNew={toggleRequestForm}
      onPoiChange={setPoiData}
    />
  );

  return (
    <div className="claim-venue-form-wrap">
      <header>
        <h5>{headerLabel}</h5>
      </header>
      {successMsg || form}
    </div>
  );
};

ClaimVenueForm.propTypes = {
  headerLabel: PropTypes.string,
};

export default ClaimVenueForm;
