import React from 'react';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select';
import AsyncSelect from 'react-select/async';
import classNames from 'classnames';
import { useToggle } from 'hooks';
import { Controller, useFormContext } from 'react-hook-form';
import HelpPopup from 'components/HelpPopup';
import useErrorOrHelpText from './useErrorOrHelpText';

const SelectField = ({
  components,
  async = false,
  asyncLoadOptions = () => {},
  asyncProps = {},
  extraProps = {},
  name,
  label,
  choices = [],
  helpText,
  helpPopupContent,
  required = false,
  disabled = false,
  validate,
  onBlur: onBlurFromProps = () => {},
  registerOpts = {},
  formMethods,
}) => {
  const { control, watch } = formMethods || useFormContext();
  const { errorOrHelpText, hasError } = useErrorOrHelpText(name, helpText, formMethods);

  const selectOptions = choices.map(choice => {
    const [value, label] = Array.isArray(choice) ? choice : [choice, choice];
    return { value, label };
  });

  const val = watch(name) || '';

  const [isFocused, toggleFocused] = useToggle(false);
  const handleFocus = () => toggleFocused(true);

  const wrapperClasses = classNames({
    'z-form-select': true,
    'no-label': !label,
    focused: isFocused,
    required,
    'has-val': !!val,
    invalid: hasError,
  });

  return (
    <div className={wrapperClasses}>
      <div style={{ position: 'relative' }}>
        <Controller
          name={name}
          control={control}
          rules={{
            required: { value: required, message: 'This field is required.' },
            disabled,
            validate,
            ...registerOpts,
          }}
          render={({
            field: { onChange, onBlur, value, ref },
          }) => {
            const handleBlur = evt => {
              toggleFocused(false);
              onBlur(evt);
              onBlurFromProps(evt);
            };

            const [SelectComponent, rsProps] = async
              ? [AsyncSelect, { loadOptions: asyncLoadOptions, defaultOptions: selectOptions, ...asyncProps, ...extraProps }]
              : [ReactSelect, { options: selectOptions, ...extraProps }];

            return (
              <SelectComponent
                ref={ref}
                components={components}
                classNamePrefix="rs"
                placeholder=""
                blurInputOnSelect
                isClearable={!required}
                isDisabled={disabled}
                value={selectOptions.find(opt => opt.value === value) || null}
                onChange={opt => onChange(opt && opt.value)}
                onFocus={handleFocus}
                onBlur={handleBlur}
                {...rsProps}
              />
            );
          }}
        />

        {label && (
          <label className={classNames('z-form-input-label', !!disabled && 'z-form-disabled')}>
            {label}
          </label>
        )}
        {!!helpPopupContent && <HelpPopup>{helpPopupContent}</HelpPopup>}
      </div>
      <div className="z-form-hint-container">
        {errorOrHelpText}
      </div>
    </div>
  );
};

SelectField.propTypes = {
  components: PropTypes.object,
  async: PropTypes.bool,
  asyncLoadOptions: PropTypes.func,
  asyncProps: PropTypes.object,
  extraProps: PropTypes.object,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  choices: PropTypes.array,
  helpText: PropTypes.string,
  helpPopupContent: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  validate: PropTypes.func,
  onBlur: PropTypes.func,
  // Pass through additional options to react-hook-form `register` method:
  // https://react-hook-form.com/api/useform/register/
  registerOpts: PropTypes.object,
  formMethods: PropTypes.object,
};

export default SelectField;
