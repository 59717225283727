import React, { useState } from 'react';
import PropTypes from 'prop-types';
import camelize from 'camelize';
import { urls } from 'app-constants';
import { getCsrfToken } from 'utils';
import { useUserContext } from 'context';
import LabeledInput from 'components/LabeledInput';
import LoadingBubbles from 'components/LoadingBubbles';

const SignUpForm = ({ onSuccess = () => {} }) => {
  const { fetchUserContext } = useUserContext();

  const defaultFieldVals = {
    username: '',
    email: '',
    password1: '',
    password2: '',
  };
  const [fieldVals, setFieldVals] = useState(defaultFieldVals);
  const handleFieldChange = evt => {
    const { name, value } = evt.target;
    const newVals = { [name]: value };
    if (name === 'email') {
      newVals.username = value;
    }
    setFieldVals(oldState => ({ ...oldState, ...newVals }));
  };

  const [errors, setErrors] = useState({});

  const [isFetching, setIsFetching] = useState(false);
  const handleFormSubmit = evt => {
    evt.preventDefault();
    const data = JSON.stringify(fieldVals);

    setIsFetching(true);
    setErrors({});
    fetch(urls.registerAjax, {
      credentials: 'include',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRFToken': getCsrfToken(),
      },
      body: data,
    })
      .then(response => {
        if (!response.ok && response.status !== 400) throw new Error(response.statusText);
        setIsFetching(false);
        if ([204, 201].includes(response.status)) {
          fetchUserContext();
          onSuccess();
        } else {
          response.json()
            .then(data => {
              if (data.user) {
                fetchUserContext();
                onSuccess();
              } else {
                setErrors(camelize(data));
              }
            });
        }
      })
      .catch(err => {
        console.error(err);
        setIsFetching(false);
      });
  };

  const renderErrors = key => {
    return (errors[key] || []).map((msg, idx) => (
      <div key={idx} className="small text-danger text-center">{msg}</div>
    ));
  };
  const hasError = key => !!(errors[key] && errors[key].length > 0);

  const errorContainerStyles = {
    minHeight: 60,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-end',
  };

  return (
    <form id="account-registration-form" onSubmit={handleFormSubmit}>
      <div className="mb-4">
        <LabeledInput
          type="text"
          name="email"
          placeholder="Email address"
          value={fieldVals.email}
          onChange={handleFieldChange}
          hasError={hasError('username') || hasError('email')}
        />
        {renderErrors('username')}
        {renderErrors('email')}
      </div>

      <div className="mb-4">
        <LabeledInput
          type="password"
          name="password1"
          placeholder="Password"
          value={fieldVals.password1}
          onChange={handleFieldChange}
          hasError={hasError('password1')}
          maskToggle
        />
        {renderErrors('password1')}
      </div>

      <div className="mb-3">
        <LabeledInput
          type="password"
          name="password2"
          placeholder="Confirm password"
          value={fieldVals.password2}
          onChange={handleFieldChange}
          hasError={hasError('password2')}
          maskToggle
        />
        {renderErrors('password2')}
      </div>

      <div className="mb-2" style={errorContainerStyles}>
        {renderErrors('nonFieldErrors')}
      </div>

      <div className="text-tertiary text-center mb-3">
        <small>
          By continuing, you agree to Zeitcaster’s <a href={urls.termsOfUse} target="_blank" rel="noopener noreferrer" className="reset-link fw-bold">Terms of Use</a>
        </small>
      </div>

      <div className="mb-3">
        <button type="submit" className="btn-z primary lg w-100 justify-content-center">
          {isFetching ? <LoadingBubbles /> : 'Sign up with email'}
        </button>
      </div>
    </form>
  );
};

SignUpForm.propTypes = {
  onSuccess: PropTypes.func,
};

export default SignUpForm;
