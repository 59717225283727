import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { urls } from 'app-constants';
import { getCsrfToken } from 'utils';
import { useResizeDetector, useTrackImpression } from 'hooks';
import BookmarkToggleButton from 'components/BookmarkToggleButton';
import CardDeletionMenu from 'components/CardDeletionMenu';
import Icon from 'components/Icon';
import PoiHoursToday from 'components/PoiHoursToday';

const PoiCard = ({
  className,
  useCompactStyle,
  smallIcon = false,
  hideBookmarkButton = false,
  hideDeletionMenu = true,
  targetBlank = false,
  id,
  absoluteUrl,
  name,
  shortAddress,
  openTimes,
  imagePrimary,
  imageThumbnail,
  numEventsFuture,
  onMouseEnter = () => {},
  onMouseLeave = () => {},
  onDeleteRequest = () => {},
  onDeleteSuccess = () => {},
  onDeleteFailure = () => {},
}) => {
  const { width, ref } = useResizeDetector({ handleHeight: false });
  const [isDeleting, setIsDeleting] = useState(false);

  const trackerRef = useTrackImpression('poi_card_impression', { venueName: name, venueId: id });

  const handleCardClick = () => {
    if (targetBlank) {
      window.open(absoluteUrl, '_blank', 'noopener,noreferrer');
    } else {
      window.location.href = absoluteUrl
    }
  };
  const handleTitleClick = evt => evt.stopPropagation(); // Don't break cmd+click

  const handleDeleteClick = () => {
    setIsDeleting(true);

    fetch(`${urls.toggleSavedPoiBase}${id}/`, {
      credentials: 'include',
      method: 'POST',
      headers: { 'X-CSRFToken': getCsrfToken() },
    })
      .then(response => {
        if (!response.ok) throw new Error(response.statusText);
        return response;
      })
      .then(response => response.json())
      .then(data => {
        if (data.saved) {
          setIsDeleting(false);
          onDeleteFailure();
        } else {
          onDeleteSuccess();
        }
      })
      .catch(err => {
        console.error(err);
        setIsDeleting(false);
        onDeleteFailure(err);
      });
  };

  const fullWidthImage = (width && width < 500) || false;
  const image = imagePrimary;
  const iconContainerStyle = {
    backgroundImage: image ? `url('${image}')` : 'unset',
  };
  if (fullWidthImage) {
    iconContainerStyle.height = Math.round(width / 2);
  }
  const iconContainerClasses = classNames({
    'lc-icon': true,
    sm: smallIcon || useCompactStyle,
    'full-width': fullWidthImage,
  });

  return (
    <div
      ref={ref}
      id={`poi-card-${id}`}
      className={classNames('listings-card', 'poi-card', className)}
      style={{ opacity: isDeleting ? 0.5 : 1 }}
      onClick={handleCardClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      data-poi-id={id}
      data-poi-name={name}
    >
      <section ref={trackerRef} className="lc-top">
        <div
          className={iconContainerClasses}
          style={iconContainerStyle}
        >
          {!image && (
            <Icon
              className="event-type-icon blue"
              i="map-marker-alt"
              size={(smallIcon || useCompactStyle) ? '3x' : '4x'}
            />
          )}
          <a href={absoluteUrl} />
        </div>
        <div className={classNames('lc-main', useCompactStyle && 'ps-0')}>
          <div>
            <h5 className={classNames('mb-3', useCompactStyle && 'h6')}>
              <a href={absoluteUrl} onClick={handleTitleClick}>{name}</a>
            </h5>

            {!!shortAddress && (
              <div className="mb-2 lh-sm d-flex align-items-center">
                <div className="me-2 d-flex justify-content-center" style={{ flex: '0 0 20px', color: '#cacaca' }}>
                  <Icon i="map-marker-alt" size="sm" />
                </div>
                <div>{shortAddress}</div>
              </div>
            )}

            {!!openTimes && openTimes.length > 0 && (
              <div className="mb-2 lh-sm d-flex align-items-center">
                <div className="me-2 d-flex justify-content-center" style={{ flex: '0 0 20px', color: '#cacaca' }}>
                  <Icon i="clock" size="sm" />
                </div>
                <PoiHoursToday openTimes={openTimes} />
              </div>
            )}

            {!!numEventsFuture && (
              <div className="mb-2 lh-sm d-flex align-items-center">
                <div className="me-2 d-flex justify-content-center" style={{ flex: '0 0 20px', color: '#cacaca' }}>
                  <Icon i={['faz', 'events']} size="sm" />
                </div>
                <div>{numEventsFuture} upcoming event{numEventsFuture !== 1 && 's'}</div>
              </div>
            )}
          </div>
        </div>

        <div className="lc-right">
          <div className="d-flex align-items-center" onClick={e => e.stopPropagation()}>
            {!hideBookmarkButton && (
              <BookmarkToggleButton
                className="ms-3"
                iconOnly
                objectType="poi"
                objectId={id}
              />
            )}
            {!hideDeletionMenu && <CardDeletionMenu onDeleteClick={handleDeleteClick} />}
          </div>
        </div>
      </section>
    </div>
  );
};

PoiCard.propTypes = {
  className: PropTypes.string,
  useCompactStyle: PropTypes.bool,
  smallIcon: PropTypes.bool,
  hideBookmarkButton: PropTypes.bool,
  hideDeletionMenu: PropTypes.bool,
  targetBlank: PropTypes.bool,
  id: PropTypes.number,
  absoluteUrl: PropTypes.string,
  name: PropTypes.string,
  shortAddress: PropTypes.string,
  openTimes: PropTypes.arrayOf(PropTypes.shape({
    dayOfWeek: PropTypes.number,
    closed: PropTypes.bool,
    timeStart: PropTypes.string,
    timeEnd: PropTypes.string,
  })),
  imagePrimary: PropTypes.string,
  imageThumbnail: PropTypes.string,
  numEventsFuture: PropTypes.number,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  onDeleteRequest: PropTypes.func,
  onDeleteSuccess: PropTypes.func,
  onDeleteFailure: PropTypes.func,
};

export default PoiCard;
